/**
 * @generated SignedSource<<a52b67cb92927a7327c235951ada49c4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProfileSettingsAccountTabEmailSectionFragment$data = {
  readonly email: string;
  readonly hasSocialAuth: boolean;
  readonly canChangeEmail: boolean;
  readonly isTest: boolean;
  readonly " $fragmentType": "ProfileSettingsAccountTabEmailSectionFragment";
};
export type ProfileSettingsAccountTabEmailSectionFragment$key = {
  readonly " $data"?: ProfileSettingsAccountTabEmailSectionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProfileSettingsAccountTabEmailSectionFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProfileSettingsAccountTabEmailSectionFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasSocialAuth",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canChangeEmail",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isTest",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "1f4ba3f86d0c6371b35732852c7bfe0d";

export default node;
