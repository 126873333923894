/**
 * @generated SignedSource<<ac7915942e56d1474e8d28c74053b489>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProfileSettingsProfileBannerFragment$data = {
  readonly id: string;
  readonly cover: string | null;
  readonly isTest: boolean;
  readonly " $fragmentSpreads": FragmentRefs<"ProfileAvatarFragment" | "ProfileNameWithTagFragment">;
  readonly " $fragmentType": "ProfileSettingsProfileBannerFragment";
};
export type ProfileSettingsProfileBannerFragment$key = {
  readonly " $data"?: ProfileSettingsProfileBannerFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProfileSettingsProfileBannerFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProfileSettingsProfileBannerFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cover",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProfileAvatarFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProfileNameWithTagFragment"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isTest",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "16f45d34934895b10aae67bf03f09154";

export default node;
