/**
 * @generated SignedSource<<73e23e40026dfd860ee4f3a315b56f74>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type MisconfiguredStripeObjectType = "product_deleted" | "product_archived" | "price_deleted" | "price_archived" | "price_changed" | "%future added value";
export type MisconfiguredStripeObjectsListQuery$variables = {
  id: string;
};
export type MisconfiguredStripeObjectsListQuery$data = {
  readonly product: {
    readonly id?: string;
    readonly misconfiguredStripeObjects?: {
      readonly __id: string;
      readonly totalCount: number;
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly type: MisconfiguredStripeObjectType;
        };
      }>;
    };
  } | null;
};
export type MisconfiguredStripeObjectsListQuery = {
  variables: MisconfiguredStripeObjectsListQuery$variables;
  response: MisconfiguredStripeObjectsListQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "totalCount",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "MisconfiguredStripeObjectNodeEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "MisconfiguredStripeObject",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cursor",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "PageInfoObjectType",
    "kind": "LinkedField",
    "name": "pageInfo",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "endCursor",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasNextPage",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "kind": "ClientExtension",
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__id",
        "storageKey": null
      }
    ]
  }
],
v5 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MisconfiguredStripeObjectsListQuery",
    "selections": [
      {
        "alias": "product",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              {
                "alias": "misconfiguredStripeObjects",
                "args": null,
                "concreteType": "MisconfiguredStripeObjectNodeConnection",
                "kind": "LinkedField",
                "name": "__MisconfiguredStripeObjectsList__misconfiguredStripeObjects_connection",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": null
              }
            ],
            "type": "Product",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MisconfiguredStripeObjectsListQuery",
    "selections": [
      {
        "alias": "product",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v5/*: any*/),
                "concreteType": "MisconfiguredStripeObjectNodeConnection",
                "kind": "LinkedField",
                "name": "misconfiguredStripeObjects",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": "misconfiguredStripeObjects(first:10)"
              },
              {
                "alias": null,
                "args": (v5/*: any*/),
                "filters": null,
                "handle": "connection",
                "key": "MisconfiguredStripeObjectsList__misconfiguredStripeObjects",
                "kind": "LinkedHandle",
                "name": "misconfiguredStripeObjects"
              }
            ],
            "type": "Product",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9699da4dcbac30ee64feff216b2349b6",
    "id": null,
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "product",
            "misconfiguredStripeObjects"
          ]
        }
      ]
    },
    "name": "MisconfiguredStripeObjectsListQuery",
    "operationKind": "query",
    "text": "query MisconfiguredStripeObjectsListQuery(\n  $id: ID!\n) {\n  product: node(id: $id) {\n    __typename\n    ... on Product {\n      id\n      misconfiguredStripeObjects(first: 10) {\n        totalCount\n        edges {\n          node {\n            id\n            type\n            __typename\n          }\n          cursor\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "03a8450ab3350e89941884d91200d5c7";

export default node;
