/**
 * @generated SignedSource<<5c0627bc1d65acbfe1e8e5bd0d577833>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProfileListModalListItemFragment$data = {
  readonly id: string;
  readonly firstName: string | null;
  readonly lastName: string | null;
  readonly fullName: string;
  readonly " $fragmentSpreads": FragmentRefs<"ProfileAvatarFragment">;
  readonly " $fragmentType": "ProfileListModalListItemFragment";
};
export type ProfileListModalListItemFragment$key = {
  readonly " $data"?: ProfileListModalListItemFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProfileListModalListItemFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProfileListModalListItemFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fullName",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProfileAvatarFragment"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "abcd404693b4ec1d5bef4b2392175c4a";

export default node;
