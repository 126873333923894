/**
 * @generated SignedSource<<e2fad39810ae17e75a57cf1ce6cdae32>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OrganizationRole = "owner" | "admin" | "member" | "%future added value";
export type ProductRole = "manager" | "instructor" | "member" | "%future added value";
export type ProfilePopoverFieldsQuery$variables = {
  userId: string;
  productId: string;
  organizationId: string;
};
export type ProfilePopoverFieldsQuery$data = {
  readonly node: {
    readonly __typename: "User";
    readonly id: string;
    readonly fullName: string;
    readonly bio: string;
    readonly organizationMembership: {
      readonly id: string;
      readonly role: OrganizationRole;
      readonly " $fragmentSpreads": FragmentRefs<"useMemberGroupTagsList_OrganizationMembershipFragment">;
    } | null;
    readonly productMembership: {
      readonly id: string;
      readonly role: ProductRole;
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"ProfileAvatarFragment">;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null;
};
export type ProfilePopoverFieldsQuery = {
  variables: ProfilePopoverFieldsQuery$variables;
  response: ProfilePopoverFieldsQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "productId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userId"
},
v3 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "userId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullName",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bio",
  "storageKey": null
},
v8 = [
  {
    "kind": "Variable",
    "name": "organizationId",
    "variableName": "organizationId"
  }
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "role",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "productId",
      "variableName": "productId"
    }
  ],
  "concreteType": "ProductMembership",
  "kind": "LinkedField",
  "name": "productMembership",
  "plural": false,
  "selections": [
    (v5/*: any*/),
    (v9/*: any*/)
  ],
  "storageKey": null
},
v11 = [
  {
    "kind": "Literal",
    "name": "groupKinds",
    "value": [
      "custom"
    ]
  }
],
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ProfilePopoverFieldsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ProfileAvatarFragment"
              },
              {
                "alias": null,
                "args": (v8/*: any*/),
                "concreteType": "OrganizationMembership",
                "kind": "LinkedField",
                "name": "organizationMembership",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v9/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "useMemberGroupTagsList_OrganizationMembershipFragment"
                  }
                ],
                "storageKey": null
              },
              (v10/*: any*/)
            ],
            "type": "User",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ProfilePopoverFieldsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "avatar",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isTest",
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v8/*: any*/),
                "concreteType": "OrganizationMembership",
                "kind": "LinkedField",
                "name": "organizationMembership",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": (v11/*: any*/),
                    "concreteType": "MemberGroupMembershipNodeConnection",
                    "kind": "LinkedField",
                    "name": "groupMemberships",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "MemberGroupMembershipNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "MemberGroupMembership",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "productId",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "MemberGroup",
                                "kind": "LinkedField",
                                "name": "memberGroup",
                                "plural": false,
                                "selections": [
                                  (v5/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "parentMemberGroupId",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "visibility",
                                    "storageKey": null
                                  },
                                  (v12/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "kind",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "color",
                                    "storageKey": null
                                  },
                                  (v9/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "isSystem",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Product",
                                    "kind": "LinkedField",
                                    "name": "product",
                                    "plural": false,
                                    "selections": [
                                      (v5/*: any*/),
                                      (v12/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              (v4/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "cursor",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PageInfoObjectType",
                        "kind": "LinkedField",
                        "name": "pageInfo",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "endCursor",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hasNextPage",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "groupMemberships(groupKinds:[\"custom\"])"
                  },
                  {
                    "alias": null,
                    "args": (v11/*: any*/),
                    "filters": [
                      "groupKinds"
                    ],
                    "handle": "connection",
                    "key": "useMemberGroupTagsList_OrganizationMembershipFragment_groupMemberships",
                    "kind": "LinkedHandle",
                    "name": "groupMemberships"
                  }
                ],
                "storageKey": null
              },
              (v10/*: any*/)
            ],
            "type": "User",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e729f4c622cdc3e55e01e14495913886",
    "id": null,
    "metadata": {},
    "name": "ProfilePopoverFieldsQuery",
    "operationKind": "query",
    "text": "query ProfilePopoverFieldsQuery(\n  $userId: ID!\n  $productId: ID!\n  $organizationId: ID!\n) {\n  node(id: $userId) {\n    __typename\n    ... on User {\n      id\n      fullName\n      bio\n      ...ProfileAvatarFragment\n      organizationMembership(organizationId: $organizationId) {\n        id\n        role\n        ...useMemberGroupTagsList_OrganizationMembershipFragment\n      }\n      productMembership(productId: $productId) {\n        id\n        role\n      }\n    }\n    id\n  }\n}\n\nfragment MemberGroupTagFragment on MemberGroup {\n  id\n  name\n  kind\n  color\n  role\n  isSystem\n  product {\n    id\n    name\n  }\n}\n\nfragment ProfileAvatarFragment on User {\n  id\n  fullName\n  avatar\n  isTest\n}\n\nfragment useMemberGroupTagsList_OrganizationMembershipFragment on OrganizationMembership {\n  id\n  groupMemberships(groupKinds: [custom]) {\n    edges {\n      node {\n        id\n        productId\n        memberGroup {\n          id\n          parentMemberGroupId\n          visibility\n          ...MemberGroupTagFragment\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e4f3070ef7bf9672bdc9afa5d645c1e1";

export default node;
