import CommentUtils from "@/comments/utils/CommentUtils"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import ProfileAvatar, {
  ProfileAvatarProps,
  ProfileAvatarSize,
} from "@/user/common/avatar/ProfileAvatar"
import { ProfileAvatarWithDetailsFragment$key } from "@/user/common/profile-avatar-with-details/__generated__/ProfileAvatarWithDetailsFragment.graphql"
import OpenProfilePopoverButton from "@/user/common/profile-popover/OpenProfilePopoverButton"
import styleIf from "@assets/style/util/styleIf"
import AvatarPlaceholder from "@components/avatar/placeholder/AvatarPlaceholder"
import {
  DiscoText,
  DiscoTextProps,
  DiscoTextSkeleton,
  GroovyTextColorKind,
} from "@disco-ui"
import { ClassNameMap } from "@material-ui/core/styles/withStyles"
import { Skeleton } from "@material-ui/lab"
import { TestIDProps } from "@utils/typeUtils"
import classNames from "classnames"
import { ReactNode } from "react"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

type ProfileAvatarWithDetails = {
  /** This key won't exist for users that are no longer part of a product (ie. they left) */
  userKey: ProfileAvatarWithDetailsFragment$key | null
  title?: ReactNode
  details?: string | ReactNode
  className?: string
  classes?: ClassNameMap<"details">
  avatarSize?: ProfileAvatarSize
  color?: GroovyTextColorKind
  linkToProfile?: boolean
  disableClick?: boolean
  stopClickPropagation?: boolean
  titleVariant?: DiscoTextProps["variant"]
  titleIcon?: React.ReactNode
  subtitleVariant?: DiscoTextProps["variant"]
  truncateName?: number
  hideAvatar?: boolean
  horizontal?: boolean
  highlight?: "full" | "details" | "none"
} & TestIDProps &
  Pick<ProfileAvatarProps, "badgeIcon" | "showTestUserTag" | "dropShadow">

function ProfileAvatarWithDetails(props: ProfileAvatarWithDetails) {
  const {
    userKey,
    title,
    details,
    className: customClassName,
    classes: customClasses,
    avatarSize,
    color,
    dropShadow,
    titleVariant,
    subtitleVariant,
    linkToProfile = true,
    disableClick = false,
    stopClickPropagation = false,
    testid = "ProfileAvatarWithDetails",
    truncateName,
    titleIcon,
    hideAvatar = false,
    badgeIcon,
    horizontal = false,
    highlight = "none",
    showTestUserTag,
  } = props
  const classes = useStyles({
    avatarSize,
    dropShadow,
    hideAvatar,
    horizontal,
    highlight,
  })

  const user = useFragment<ProfileAvatarWithDetailsFragment$key>(
    graphql`
      fragment ProfileAvatarWithDetailsFragment on User {
        id
        fullName
        ...ProfileAvatarFragment
      }
    `,
    userKey
  )

  const userDetails = user || {
    fullName: CommentUtils.getUnknownUserFullName(),
  }

  const content = (
    <div className={classNames(classes.container, customClassName)} data-testid={testid}>
      {!hideAvatar && (
        <ProfileAvatar
          testid={`${testid}.ProfileAvatar`}
          userKey={user}
          dropShadow={dropShadow}
          size={avatarSize ?? 40}
          badgeIcon={badgeIcon}
          showTestUserTag={showTestUserTag}
        />
      )}
      <div className={classes.detailsContainer}>
        {title || (
          <DiscoText
            variant={titleVariant || "body-md-600"}
            truncateText={truncateName}
            testid={`${testid}.member-fullname`}
            // Hide name in FS recordings
            className={classNames(classes.name, "fs-mask", {
              [classes.nameWithIcon]: Boolean(titleIcon),
            })}
            color={color}
          >
            {userDetails.fullName}
            {titleIcon}
          </DiscoText>
        )}

        {details && (
          <div className={classNames(classes.details, customClasses?.details)}>
            {typeof details === "string" ? (
              <DiscoText
                variant={subtitleVariant || "body-xs-500-uppercase"}
                testid={`${testid}.details`}
                truncateText={1}
                color={color || "text.secondary"}
                className={classNames(classes.detailsText, {
                  // Hide from FS if the value seems like an email
                  "fs-mask": details.includes("@"),
                })}
                title={details}
              >
                {details}
              </DiscoText>
            ) : (
              details
            )}
          </div>
        )}
      </div>
    </div>
  )

  if (disableClick) return content

  return (
    <>
      {linkToProfile && user ? (
        <OpenProfilePopoverButton userId={user.id} stopPropagation={stopClickPropagation}>
          {(buttonProps) => {
            return (
              <div {...buttonProps} className={classes.clickable}>
                {content}
              </div>
            )
          }}
        </OpenProfilePopoverButton>
      ) : (
        content
      )}
    </>
  )
}

type StyleProps = {
  avatarSize?: number
  dropShadow?: boolean
  hideAvatar?: boolean
  horizontal?: boolean
  highlight?: "full" | "details" | "none"
}

type PlaceholderAvatarWithDetailsProps = Omit<
  ProfileAvatarWithDetails,
  "userKey" | "linkToProfile"
> & {
  userName: string
  uuid: string
}

export function PlaceholderAvatarWithDetails(props: PlaceholderAvatarWithDetailsProps) {
  const {
    userName,
    details,
    uuid,
    className: customClassName,
    avatarSize,
    dropShadow,
    titleVariant,
    subtitleVariant,
    testid = "PlaceholderAvatarWithDetails",
  } = props
  const classes = useStyles({ avatarSize, dropShadow })

  return (
    <div className={classNames(classes.container, customClassName)} data-testid={testid}>
      <AvatarPlaceholder
        testid={`${testid}.PlaceholderAvatar`}
        dropShadow={dropShadow}
        text={userName[0] || "P"}
        uuid={uuid}
        size={avatarSize ?? 40}
        isTestUser={false}
      />
      <div className={classes.detailsContainer}>
        <DiscoText
          variant={titleVariant || "body-sm-700"}
          className={classes.name}
          testid={`${testid}.member-fullname`}
        >
          {userName}
        </DiscoText>

        {details && (
          <div className={classes.details}>
            {typeof details === "string" ? (
              <DiscoText
                variant={subtitleVariant || "body-xs-500-uppercase"}
                className={classes.detailsText}
                testid={`${testid}.details`}
                truncateText={1}
                color={"groovy.grey.400"}
              >
                {details}
              </DiscoText>
            ) : (
              details
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export function ProfileAvatarWithDetailsSkeleton({
  avatarSize,
  showDetails = false,
}: Pick<ProfileAvatarWithDetails, "avatarSize"> & { showDetails?: boolean }) {
  const classes = useStyles({})
  return (
    <div className={classes.container}>
      <Skeleton variant={"circle"} width={avatarSize ?? 40} height={avatarSize ?? 40} />
      <div>
        <DiscoTextSkeleton width={100} />
        {showDetails && <DiscoTextSkeleton width={75} />}
      </div>
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  container: ({ avatarSize, hideAvatar, highlight }: StyleProps) => ({
    display: "grid",
    gridTemplateColumns: hideAvatar ? "1fr" : `${avatarSize ?? 40}px 1fr`,
    gap: theme.spacing(1.5),
    alignItems: "center",
    minWidth: "150px",

    ...styleIf(highlight === "full", {
      marginTop: theme.spacing(-1),
      paddingTop: theme.spacing(1),
      marginRight: theme.spacing(-0.5),
      marginLeft: theme.spacing(-1),
      paddingLeft: theme.spacing(1),
      backgroundColor: theme.palette.groovy.blue[100],
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
    }),
  }),
  clickable: {
    cursor: "pointer",
  },
  detailsContainer: ({ horizontal, highlight }: StyleProps) => ({
    minWidth: "0",
    overflowWrap: "anywhere",
    ...styleIf(horizontal, {
      display: "flex",
      alignItems: "flex-start",
      height: "100%",
    }),
    ...styleIf(highlight === "details", {
      marginTop: theme.spacing(-1),
      paddingTop: theme.spacing(0.5),
      marginRight: theme.spacing(-1.25),
      marginLeft: theme.spacing(-1),
      paddingLeft: theme.spacing(1),
      backgroundColor: theme.palette.groovy.blue[100],
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
    }),
  }),
  name: ({ horizontal }: StyleProps) => ({
    fontWeight: 600,
    textAlign: "left",
    ...styleIf(horizontal, {
      marginRight: theme.spacing(1),
    }),
  }),
  nameWithIcon: {
    display: "flex",
    alignItems: "center",
  },
  details: ({ horizontal }: StyleProps) => ({
    marginTop: theme.spacing(-0.25),
    ...styleIf(horizontal, {
      display: "flex",
      alignItems: "center",
    }),
  }),
  detailsText: {
    fontWeight: 500,
    textTransform: "inherit",
  },
}))

export default ProfileAvatarWithDetails
