/**
 * @generated SignedSource<<9d717dd403fc456d265eaee8b85adbd9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SlackUserImportMemberUnmappedStatus = "all" | "unmatched" | "pending" | "%future added value";
export type UnmappedSlackUserReportTableQuery$variables = {
  id: string;
  first?: number | null;
  after?: string | null;
  last?: number | null;
  before?: string | null;
  status?: SlackUserImportMemberUnmappedStatus | null;
  search?: string | null;
};
export type UnmappedSlackUserReportTableQuery$data = {
  readonly organization: {
    readonly slackUserImport?: {
      readonly " $fragmentSpreads": FragmentRefs<"UnmappedSlackUserReportTable_PaginationFragment">;
    } | null;
  } | null;
};
export type UnmappedSlackUserReportTableQuery = {
  variables: UnmappedSlackUserReportTableQuery$variables;
  response: UnmappedSlackUserReportTableQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "before"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "status"
},
v7 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v8 = {
  "kind": "Variable",
  "name": "after",
  "variableName": "after"
},
v9 = {
  "kind": "Variable",
  "name": "before",
  "variableName": "before"
},
v10 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v11 = {
  "kind": "Variable",
  "name": "last",
  "variableName": "last"
},
v12 = {
  "kind": "Variable",
  "name": "search",
  "variableName": "search"
},
v13 = {
  "kind": "Variable",
  "name": "status",
  "variableName": "status"
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v15 = [
  (v8/*: any*/),
  (v9/*: any*/),
  (v10/*: any*/),
  (v11/*: any*/),
  (v12/*: any*/),
  (v13/*: any*/),
  {
    "kind": "Literal",
    "name": "unmapped",
    "value": true
  }
],
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullName",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UnmappedSlackUserReportTableQuery",
    "selections": [
      {
        "alias": "organization",
        "args": (v7/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SlackUserImport",
                "kind": "LinkedField",
                "name": "slackUserImport",
                "plural": false,
                "selections": [
                  {
                    "args": [
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/)
                    ],
                    "kind": "FragmentSpread",
                    "name": "UnmappedSlackUserReportTable_PaginationFragment"
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Organization",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/),
      (v4/*: any*/),
      (v1/*: any*/),
      (v6/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Operation",
    "name": "UnmappedSlackUserReportTableQuery",
    "selections": [
      {
        "alias": "organization",
        "args": (v7/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v14/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SlackUserImport",
                "kind": "LinkedField",
                "name": "slackUserImport",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": (v15/*: any*/),
                    "concreteType": "SlackUserImportMemberNodeConnection",
                    "kind": "LinkedField",
                    "name": "members",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "totalCount",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SlackUserImportMemberNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "cursor",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "SlackUserImportMember",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v16/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "OrganizationMembership",
                                "kind": "LinkedField",
                                "name": "organizationMembership",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "User",
                                    "kind": "LinkedField",
                                    "name": "member",
                                    "plural": false,
                                    "selections": [
                                      (v16/*: any*/),
                                      (v17/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "avatar",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "isTest",
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  },
                                  (v18/*: any*/),
                                  (v16/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "SlackUserImportMember",
                                "kind": "LinkedField",
                                "name": "mappedSlackUser",
                                "plural": false,
                                "selections": [
                                  (v16/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "firstName",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "lastName",
                                    "storageKey": null
                                  },
                                  (v17/*: any*/),
                                  (v18/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "slackUserIconUrl",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "isFuzzyMatch",
                                "storageKey": null
                              },
                              (v14/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PageInfoObjectType",
                        "kind": "LinkedField",
                        "name": "pageInfo",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "endCursor",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "startCursor",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hasNextPage",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hasPreviousPage",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "kind": "ClientExtension",
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__id",
                            "storageKey": null
                          }
                        ]
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": (v15/*: any*/),
                    "filters": [
                      "unmapped",
                      "status",
                      "search"
                    ],
                    "handle": "connection",
                    "key": "UnmappedSlackUserReportTable_members",
                    "kind": "LinkedHandle",
                    "name": "members"
                  },
                  (v16/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "Organization",
            "abstractKey": null
          },
          (v16/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5620e9167dfa148e26072fa323067e23",
    "id": null,
    "metadata": {},
    "name": "UnmappedSlackUserReportTableQuery",
    "operationKind": "query",
    "text": "query UnmappedSlackUserReportTableQuery(\n  $id: ID!\n  $first: Int\n  $after: String\n  $last: Int\n  $before: String\n  $status: SlackUserImportMemberUnmappedStatus\n  $search: String\n) {\n  organization: node(id: $id) {\n    __typename\n    ... on Organization {\n      slackUserImport {\n        ...UnmappedSlackUserReportTable_PaginationFragment_1cTqlv\n        id\n      }\n    }\n    id\n  }\n}\n\nfragment ProfileAvatarFragment on User {\n  id\n  fullName\n  avatar\n  isTest\n}\n\nfragment ProfileAvatarWithDetailsFragment on User {\n  id\n  fullName\n  ...ProfileAvatarFragment\n}\n\nfragment SlackUserAvatarWithEmailFragment on SlackUserImportMember {\n  id\n  firstName\n  lastName\n  fullName\n  email\n  slackUserIconUrl\n}\n\nfragment SlackUserProfileDropdownFragment on SlackUserImportMember {\n  id\n  mappedSlackUser {\n    ...SlackUserAvatarWithEmailFragment\n    id\n  }\n  isFuzzyMatch\n}\n\nfragment UnmappedSlackUserReportTableRowFragment on SlackUserImportMember {\n  organizationMembership {\n    member {\n      ...ProfileAvatarWithDetailsFragment\n      id\n    }\n    email\n    id\n  }\n  ...SlackUserProfileDropdownFragment\n}\n\nfragment UnmappedSlackUserReportTable_PaginationFragment_1cTqlv on SlackUserImport {\n  members(unmapped: true, first: $first, after: $after, last: $last, before: $before, status: $status, search: $search) {\n    totalCount\n    edges {\n      cursor\n      node {\n        id\n        ...UnmappedSlackUserReportTableRowFragment\n        __typename\n      }\n    }\n    pageInfo {\n      endCursor\n      startCursor\n      hasNextPage\n      hasPreviousPage\n    }\n  }\n  id\n}\n"
  }
};
})();

(node as any).hash = "6365bbcd94bf4c78cff2dfb5639ba530";

export default node;
